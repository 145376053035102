import cronstrue from 'cronstrue';

export function parseCronString(cronString: string): string {
  try {
    return cronstrue.toString(cronString, {
      verbose: true,
    });
  } catch (error) {
    return `${cronString} (invalid)`;
  }
}
export default parseCronString;
