import readJsonFile from '../actions/json/readJsonFile';

export async function readIndex<T>(args: {
  userAddress?: string
  indexName: string
}): Promise<T | null> {
  const { userAddress, indexName } = args;
  try {
    let fileKey: string;
    if (userAddress) {
      fileKey = `users/${userAddress}/indexes/${indexName}.json`;
    } else {
      fileKey = `indexes/${indexName}.json`;
    }
    const data = await readJsonFile<T>({ s3ObjectKey: fileKey });
    return data ?? null;
  } catch (error) {
    console.error(`[fetchIndex(${userAddress ?? 'root'}, ${indexName})] ${error}`);
    throw error;
  }
}

export default readIndex;
