import { logger } from '#/logger';
import { IWorkspace } from '#/types/workspace';

import upsertJsonFile from '../../actions/json/upsertJsonFile';
import readIndex from '../readIndex';

type WorkspaceInfo = Omit<IWorkspace, 'nodes' | 'edges'>;

export interface WorkspacesIndex {
  workspaces: Record<string, WorkspaceInfo>;
  updatedAt: string;
}

function _updateWorkspacesIndex(args: {
  workspaceItems: WorkspacesIndex['workspaces'],
  workspace: WorkspaceInfo,
}) {
  const { workspaceItems, workspace } = args;

  return {
    ...workspaceItems,
    [workspace.uuid]: workspace
  };
}

export async function updateUserWorkspacesIndex(args: {
  userAddress: string;
  workspace: WorkspaceInfo;
  updatedAt?: string;
}) {
  const { userAddress, workspace, updatedAt = new Date().toISOString() } = args;
  try {
    const index = await readIndex<WorkspacesIndex>({
      userAddress,
      indexName: 'workspaces',
    }) ?? { workspaces: {} };

    const workspaces = _updateWorkspacesIndex({
      workspaceItems: index.workspaces ?? {},
      workspace: {
        ...workspace,
        updatedAt,
      },
    });

    await upsertJsonFile({
      s3ObjectKey: `users/${userAddress}/workspaces/index.json`,
      updatedJson: { workspaces },
    });

    return workspaces;
  } catch (error: any) {
    logger.error(`Error updating workspaces index: ${error.message}`);
    throw error;
  }
}

export default updateUserWorkspacesIndex;
