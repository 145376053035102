import { debug } from '#/utils/debug';

import superjson from 'superjson';

import { AWS_S3_BUCKET } from '#/config/constants';
import getS3Object from '../getS3Object';

function parseS3ObjectBodyJson<T = Record<string, any>>(args: {
  s3Object: any;
}) {
  const { s3Object } = args;

  if (!s3Object?.Body) {
    return {} as T;
  }

  // console.debug('[parseS3ObjectBodyJson] s3Object', s3Object);

  const bodyStr = typeof s3Object?.Body !== 'string'
    ? s3Object?.Body?.toString()
    : s3Object?.Body;

  // console.debug('[parseS3ObjectBodyJson] bodyStr', bodyStr);

  try {
    // Try superjson first
    const json = superjson.parse<T>(bodyStr);
    // console.debug('[parseS3ObjectBodyJson] json', json);

    if (json == null) {
      throw new Error('Failed to parse JSON with superjson');
    }

    return json;
  } catch (e) {
    // Fallback to regular JSON parse
    try {
      const json = JSON.parse(bodyStr) as T;
      // console.debug('[parseS3ObjectBodyJson] json', json);
      return json;
    } catch (e2) {
      console.warn('Failed to parse JSON with both superjson and JSON.parse');
      return {} as T;
    }
  }
}

export async function readJsonFile<T = Record<string, any>>(args: {
  s3ObjectKey: string;
}): Promise<T | null> {
  try {
    const { s3ObjectKey } = args;

    const s3Object = await getS3Object({
      Bucket: AWS_S3_BUCKET,
      Key: s3ObjectKey,
    });

    // console.debug('[readJsonFile] s3Object', s3Object);

    if (!s3Object) {
      debug(readJsonFile, 'File not found:', s3ObjectKey);
      return null;
    }

    const json = parseS3ObjectBodyJson<T>({ s3Object });
    // console.debug('[readJsonFile] json', json);
    return json;
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error reading JSON file: ${error.message}`);
  }
}

export default readJsonFile;
