import { v4 as uuidv4 } from 'uuid';
import { IWorkspace } from '#/types/workspace';
import createDefaultWorkspace from '../workflows/mockData/workflow';
import writeWorkspace from './writeWorkspace';

export async function getNewWorkspace(args: {
  ownerAddress: string;
  workspaceName?: string;
  workspaceDescription?: string;
  workspaceIcons?: string[];
}): Promise<IWorkspace> {
  try {
    const date = new Date();
    // const timestampMs = date.getTime();
    const timestampIso = date.toISOString();

    const {
      ownerAddress,
      workspaceName = 'New Workspace',
      workspaceDescription = 'My first workspace',
      workspaceIcons = ['FaBuilding', 'FaCode', 'FaDatabase'],
    } = args;

    const workspaceUuid = uuidv4();

    const defaultWorkspace = (await createDefaultWorkspace()) ?? {};

    const workspace = {
      id: workspaceUuid,
      uuid: workspaceUuid,
      name: workspaceName,
      description: workspaceDescription,
      icons: workspaceIcons,
      owner: ownerAddress,
      context: defaultWorkspace?.context ?? {},
      nodes: defaultWorkspace?.nodes ?? [],
      edges: defaultWorkspace?.edges ?? [],
      createdAt: timestampIso,
      updatedAt: timestampIso,
    };

    return await writeWorkspace({
      ownerAddress,
      workspaceUuid,
      workspace,
    });
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error creating new workspace: ${error.message}`);
  }
}
export default getNewWorkspace;
