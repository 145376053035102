import upsertJsonFile from '#/services/s3/actions/json/upsertJsonFile';
import { IWorkflow } from '#/types/workspace';
import { PATH_USERS, pathJoin } from '#/services/s3/filepaths';
import { stringifyJson } from '#/utils/json';

export async function upsertWorkflowJson(args: {
  ownerAddress: string;
  workflowUuid: string;
  json: Partial<IWorkflow>;
}): Promise<IWorkflow> {
  const { ownerAddress, workflowUuid, json } = args;

  const userHome = pathJoin(PATH_USERS, ownerAddress);
  const userWorkflowsFolderKey = pathJoin(userHome, 'workflows/');
  const workflowKey = pathJoin(
    userWorkflowsFolderKey,
    `${workflowUuid}.json`,
  );

  console.debug('[upsertWorkflowJson] stringifyJson(workflowKey)');
  console.debug('[upsertWorkflowJson] ' + stringifyJson(workflowKey));
  console.debug('[upsertWorkflowJson] stringifyJson(json)');
  console.debug('[upsertWorkflowJson] ' + stringifyJson(json));

  // TODO: overwrite instead of merge
  return await upsertJsonFile({
    s3ObjectKey: workflowKey,
    updatedJson: json,
  }) as IWorkflow;
}

export async function upsertWorkflowVersionJson(args: {
  ownerAddress: string;
  workflowUuid: string;
  json: IWorkflow;
}): Promise<IWorkflow> {
  const { ownerAddress, workflowUuid, json } = args;

  const version = json?.version;

  const userHome = pathJoin(PATH_USERS, ownerAddress);
  const userWorkflowVersionsFolderKey = pathJoin(
    userHome,
    'workflows',
    workflowUuid,
    'versions/',
  );
  const workflowVersionKey = pathJoin(
    userWorkflowVersionsFolderKey,
    `${version}.json`,
  );

  console.debug('[upsertWorkflowVersionJson] stringifyJson(workflowVersionKey)');
  console.debug('[upsertWorkflowVersionJson] ' + stringifyJson(workflowVersionKey));
  console.debug('[upsertWorkflowVersionJson] stringifyJson(json)');
  console.debug('[upsertWorkflowVersionJson] ' + stringifyJson(json));

  // TODO: overwrite instead of merge
  return await upsertJsonFile({
    s3ObjectKey: workflowVersionKey,
    updatedJson: json,
  }) as IWorkflow;
}

export async function writeWorkflow(args: {
  ownerAddress: string;
  workflowUuid: string;
  workflow: IWorkflow;
}): Promise<IWorkflow> {
  try {
    const date = new Date();
    const timestampMs = date.getTime();
    const timestampIso = date.toISOString();

    const { ownerAddress, workflowUuid, workflow } = args;

    const version = timestampMs;
    const updatedAt = timestampIso;

    const newWorkflow = {
      ...workflow,
      version,
      updatedAt,
    };

    console.debug('[writeWorkflow] stringifyJson(newWorkflow)');
    console.debug('[writeWorkflow] ' + stringifyJson(newWorkflow));

    // TODO: overwrite instead of merge
    await upsertWorkflowJson({
      ownerAddress,
      workflowUuid,
      json: newWorkflow,
    });

    // // DISABLED for now, storj s3 gateway was overwhelmed by the number of objects/segments
    // // TODO: overwrite instead of merge
    // await upsertWorkflowVersionJson({
    //   ownerAddress,
    //   workflowUuid,
    //   json: newWorkflow,
    // });

    return newWorkflow;
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error writing workflow: ${error.message}`);
  }
}
export default writeWorkflow;
