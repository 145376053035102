import { v4 as uuidv4 } from 'uuid';
import { IWorkspace } from '#/types/workspace';
import getNewWorkspace from './getNewWorkspace';
import updateWorkspace from './updateWorkspace';

export async function newWorkspaceFromTemplate(args: {
  template: IWorkspace;
  newOwnerAddress: string;
}) {
  const { template, newOwnerAddress } = args;
  const newWorkspace = await getNewWorkspace({
    ownerAddress: newOwnerAddress,
    workspaceName: template.name,
    workspaceDescription: template.description,
    workspaceIcons: template.icons,
  });
  console.debug('[newWorkspaceFromTemplate] newWorkspace:', newWorkspace);
  const updatedWorkspace = {
    ...newWorkspace,
    templateId: template.templateId,
    templateVersion: template.version,
    templateOwner: template.templateOwner,
    name: template.name,
    description: template.description,
    icons: template.icons,
    context: template.context,
    coverImageUrl: template.coverImageUrl,
    coverRevealImageUrl: template.coverRevealImageUrl,
    nodes: (template?.nodes ?? []).map((node) => ({ ...node, data: { ...node.data, uuid: uuidv4() } })),
    edges: template.edges,
  };
  return await updateWorkspace({
    ownerAddress: newOwnerAddress,
    workspaceUuid: newWorkspace.uuid,
    updatedWorkspace,
  });
}

export default newWorkspaceFromTemplate;
