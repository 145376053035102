import { IWorkflow } from '#/types/workspace';
import { stringifyJson } from '#/utils/json';

import getWorkflow from './getWorkflow';
import writeWorkflow from './writeWorkflow';

export default async function updateWorkflow(args: {
  ownerAddress: string;
  workflowUuid: string;
  updatedWorkflow: Partial<IWorkflow>;
}): Promise<IWorkflow> {
  try {
    const { ownerAddress, workflowUuid, updatedWorkflow } = args;

    const currentWorkflow =
      (await getWorkflow({
        ownerAddress,
        workflowUuid,
      })) ?? {};

    console.debug('[updateWorkflow] stringifyJson(currentWorkflow)');
    console.debug('[updateWorkflow] ' + stringifyJson(currentWorkflow));

    const mergedWorkflow = {
      uuid: workflowUuid,
      ...currentWorkflow,
      ...updatedWorkflow,
    };

    console.debug('[updateWorkflow] stringifyJson(mergedWorkflow)');
    console.debug('[updateWorkflow] ' + stringifyJson(mergedWorkflow));

    const newWorkflow = await writeWorkflow({
      ownerAddress,
      workflowUuid,
      workflow: mergedWorkflow,
    });

    return newWorkflow;
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error updating workflow: ${error.message}`);
  }
}
