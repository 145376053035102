import { PATH_USERS, pathJoin } from '#/services/s3/filepaths';
import deleteFile from '#/services/s3/actions/deleteFile';
import listAllObjects from '#/services/s3/actions/listAllObjects';

export async function deleteWorkspace(args: {
  ownerAddress: string;
  workflowUuid: string;
}): Promise<void> {
  try {
    const { ownerAddress, workflowUuid } = args;

    const userHome = pathJoin(PATH_USERS, ownerAddress);

    const userWorkspacesFolderKey = pathJoin(userHome, 'workspaces/');
    const workspaceKey = pathJoin(userWorkspacesFolderKey, `${workflowUuid}.json`);

    // Delete the main workspace file
    await deleteFile({
      s3ObjectKey: workspaceKey,
    });

    const userWorkflowsFolderKey = pathJoin(userHome, 'workflows/');
    const workflowKey = pathJoin(userWorkflowsFolderKey, `${workflowUuid}.json`);

    // Delete the main workflow file
    await deleteFile({
      s3ObjectKey: workflowKey,
    });

    const userWorkflowVersionsFolderKey = pathJoin(
      userHome,
      'workflows',
      workflowUuid,
      'versions/',
    );

    // Ideally, list all version files first and delete them one by one
    // This pseudocode assumes a function `listJsonFiles` that lists all files under a specific key
    const versionFiles = await listAllObjects({ folderKey: userWorkflowVersionsFolderKey });
    for (const fileKey of versionFiles) {
      await deleteFile({
        s3ObjectKey: fileKey,
      });
    }

    // Optionally, remove the versions folder itself if required by the S3 service
    await deleteFile({
      s3ObjectKey: userWorkflowVersionsFolderKey,
    });

  } catch (error: any) {
    console.error(error);
    throw new Error(`Error deleting workflow: ${error.message}`);
  }
}
export default deleteWorkspace;
