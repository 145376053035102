'use client';

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAsyncMemo } from '#/hooks/useAsyncMemo';
import { identifyUser as logrocketIdentifyUser } from '#/services/logrocket';
import { User } from '#/services/user';
import { IProfile } from '#/types/profile';
import { UserSession } from '#/types/user';
type SetterFn<T> = (arg1: T) => void;
type Setter<T> = ((fn: SetterFn<T>) => void) | SetterFn<T>;
interface UserContextValues {
  isLoading: boolean;
  userSession: UserSession;
  setUserSession: Setter<UserSession>;
  user: User | undefined;
  userProfile: IProfile | undefined;
  updateProfile: (updatedProfile: Partial<IProfile>) => Promise<void>;
  connectedProviders: string[];
}

// const profileDefaultValue = {
//   details: {
//     publicAddress: '0x0',
//     bio: 'Software engineer looking to use Simpfi',
//     avatarUrl: 'https://simpfi.ai/assets/images/logos/imagelogo.png',
//     location: 'Raleigh , NC',
//     websiteUrl: 'http://keepinitcoolwithsimpfi.com',
//     firstName: 'John',
//     lastName: 'Doe',
//   },
//   socials: {
//     twitter: 'https://x.com/SimpFiAi',
//     twitterUsername: '@SimpFiAi',
//   },
// };

export const UserContext = createContext<UserContextValues>({
  isLoading: false,
  userSession: undefined,
  setUserSession: () => {},
  user: undefined,
  userProfile: undefined,
  // profileDefaultValue,
  updateProfile: async () => {},
  connectedProviders: []
});
export const useUserContext = () => useContext(UserContext);
interface UserContextProviderProps {
  children: React.ReactNode;
}
const UserContextProvider = (props: UserContextProviderProps) => {
  // const redirectTo = '/dashboard';
  // const redirectIfFound = true;

  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userSession, setUserSession] = useState<UserSession>();
  // const [user, setUser] = useState<User>();

  const user = useAsyncMemo(async () => {
    if (userSession?.address == null) {
      return undefined;
    }
    const newUser = new User({
      address: userSession?.address,
      chainId: 1
    });
    // Make sure the user folder exists
    const result = await newUser.createOrRetrieveUserFolder();
    console.debug('[getUser] result:', result);
    logrocketIdentifyUser({
      user: newUser,
      userSession
    });
    return newUser;
  }, [userSession?.address]);

  // TODO: reloadUserSession
  // ...

  const [userProfile, setUserProfile] = useState<IProfile>();
  const reloadUserProfile = useCallback(async () => {
    if (user == null) {
      return undefined;
    }
    const profile = await user.getProfile();
    setUserProfile(profile ?? {});
  }, [user]);
  useEffect(() => {
    reloadUserProfile();
  }, [reloadUserProfile]);
  const updateProfile = useCallback(async (updatedProfile: Partial<IProfile>) => {
    if (user == null) {
      console.warn('Cannot update user profile when no user is loaded.');
      return;
    }
    await user.updateProfile(updatedProfile);
    reloadUserProfile();
  }, [user, reloadUserProfile]);

  // TODO: bugging out with can only use Router on client error but this is a
  // client only component...
  //
  // useEffect(() => {
  //   // if no redirect needed, just return (example: already on /dashboard)
  //   // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
  //   if (!redirectTo || !user) return;

  //   if (
  //     // If redirectTo is set, redirect if the user was not found.
  //     (redirectTo && !redirectIfFound && !user) ||
  //     // If redirectIfFound is also set, redirect if the user was found
  //     (redirectIfFound && user)
  //   ) {
  //     Router.push(redirectTo);
  //   }
  // }, [user, redirectIfFound, redirectTo]);

  const value = useMemo(() => ({
    isLoading: user == null || userProfile == null,
    userSession,
    setUserSession,
    user,
    userProfile,
    updateProfile,
    connectedProviders: userSession?.connectedProviders ?? []
  }), [user, userProfile, userSession, updateProfile]);
  return <UserContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="UserContextProvider" data-sentry-source-file="UserContext.tsx">{props.children}</UserContext.Provider>;
};
export default UserContextProvider;