import S3 from 'aws-sdk/clients/s3';
import { s3 } from '..';

export async function getS3Object(getObjectParams: S3.Types.GetObjectRequest) {
  try {
    return await s3.getObject(getObjectParams).promise();
  } catch (error: any) {
    if (error.code === 'NoSuchKey') {
      return null;
    }
    console.error(error);
    throw new Error(`Error getting S3 object: ${error.message}`);
  }
}

export default getS3Object;
