import readJsonFile from '#/services/s3/actions/json/readJsonFile';
import { IWorkspace } from '#/types/workspace';
import { PATH_USERS, pathJoin } from '#/services/s3/filepaths';

export async function getWorkspace(args: {
  ownerAddress: string;
  workspaceUuid: string;
}): Promise<any> {
  try {
    const { ownerAddress, workspaceUuid } = args;

    const userHome = pathJoin(PATH_USERS, ownerAddress);
    const userWorkspacesFolderKey = pathJoin(userHome, 'workspaces/');
    const workspaceKey = pathJoin(
      userWorkspacesFolderKey,
      `${workspaceUuid}.json`,
    );

    return await readJsonFile<IWorkspace>({
      s3ObjectKey: workspaceKey,
    });
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error getting workspace: ${error.message}`);
  }
}
export default getWorkspace;
