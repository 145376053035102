import { IWorkflow, IWorkspace } from '#/types/workspace';
import { API_ROOT } from '@/src/config/env';

import getWorkspace from './getWorkspace';
import writeWorkspace from './writeWorkspace';

export function webhookUrlFromWorkspace(workspace: IWorkspace): string {
  return `${API_ROOT}/_/users/${workspace.owner}/workflows/${workspace.uuid}/run`;
}

export function webhookUrlFromWorkflow(workflow: IWorkflow): string {
  return `${API_ROOT}/_/users/${workflow.owner}/workflows/${workflow.uuid}/run`;
}

export default async function updateWorkspace(args: {
  ownerAddress: string;
  workspaceUuid: string;
  updatedWorkspace: Partial<IWorkspace>;
}): Promise<IWorkspace> {
  try {
    const { ownerAddress, workspaceUuid, updatedWorkspace } = args;

    const currentWorkspace = await getWorkspace({
      ownerAddress,
      workspaceUuid,
    });

    const mergedWorkspace = {
      uuid: workspaceUuid,
      ...currentWorkspace,
      ...updatedWorkspace,
      webhookUrl: webhookUrlFromWorkspace(currentWorkspace),
    };

    const newWorkspace = await writeWorkspace({
      ownerAddress,
      workspaceUuid,
      workspace: mergedWorkspace,
    });

    return newWorkspace;
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error updating workspace: ${error.message}`);
  }
}
