import compact from 'lodash/compact';

import { AWS_S3_BUCKET } from '#/config/constants';
import { s3 } from '#/services/s3';
import readJsonFile from '#/services/s3/actions/json/readJsonFile';
import { IWorkspace } from '#/types/workspace';
import { PATH_USERS, pathJoin, isJsonPath } from '#/services/s3/filepaths';
import listAllObjects from '#/services/s3/actions/listAllObjects';

function isWorkspacePath(path: string | any) {
  return (
    isJsonPath(path) &&
    /0x[a-zA-Z0-9]+\/workspaces\/[a-zA-Z0-9-]+\.json/g.test(path ?? '')
  );
}

export default async function getWorkspaces(args: {
  ownerAddress: string;
}): Promise<IWorkspace[]> {
  try {
    const { ownerAddress } = args;

    const userHome = pathJoin(PATH_USERS, ownerAddress);
    const userWorkspacesFolderKey = pathJoin(userHome, 'workspaces/');

    const objectKeys = await listAllObjects({ folderKey: userWorkspacesFolderKey });
    const workspaceKeys = compact(objectKeys.filter(isWorkspacePath));

    const workspaces: any[] = compact(
      await Promise.all(
        workspaceKeys.map(async (workspaceKey) => {
          try {
            return await readJsonFile<IWorkspace>({
              s3ObjectKey: workspaceKey,
            });
          } catch (error: any) {
            console.error(
              `Error fetching workspace JSON ${workspaceKey}: ${error.message}`,
            );
          }
        }),
      ),
    );

    return workspaces;
  } catch (error: any) {
    console.error(error);
    console.error(`Error getting workspaces: ${error.message}`);
    // throw new Error(`Error getting workspaces: ${error.message}`);
    return [];
  }
}
